﻿/**
 *  @ngdoc controller
 *  @name Order Notes Directive
 *  @description This directive is used in order details page for RX DX AX
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('singleordernotesModel', function () {
        return {
            controller: 'singleOrderNotesController',
            restrict: 'E',
            scope: {
                modelId: "@",
                orderDet: '=',
                ctrl: '=',
                patientname: '=',
                displayOnly: '='
            },
            templateUrl: 'app/directive/orderNotes/single-orderNotesdir.html'
        }
    }).controller('singleOrderNotesController', function ($scope, toaster) {

        if ($scope.orderDet.orderNotes == undefined || $scope.orderDet.orderNotes == null) {
            $scope.orderDet.orderNotes = {};
            $scope.orderDet.orderNotes.urls = [];
        }

        $scope.ctrl.orderNotes = {};
        $scope.ctrl.orderNotes.urls = [];

        $scope.ctrl.addMore = function (id) {
            if (id == 1) {
                $scope.ctrl.isMoreNotes = true;
            } else
                $scope.ctrl.isMoreNotes = false;
        }

        $scope.ctrl.uploadLensNotes = function (event) {
            var files = event.target.files;
            $scope.ctrl.uploadOrderNotesImage(files);
        };


        /**
        * @name uploadImageForLensNotes
        * @param {any}  value value as object,
        * @description upload Image For Order Notes
        * @author Ajay Vyas
        * @returns {void}
        */
        $scope.ctrl.uploadOrderNotesImage = function (value) {
            var imageExtn;
            var filesSelected = value;

            var fileCount = ($scope.orderDet.orderNotes.urls == undefined || !$scope.orderDet.orderNotes.urls.length) ? 0 : $scope.orderDet.orderNotes.urls.filter(x => x.isDeleted !== true).length + filesSelected.length;
            if (!$scope.orderDet.orderNotes) {
                $scope.orderDet.orderNotes = {};
                $scope.orderDet.orderNotes.urls = [];
            }
            if (fileCount > 3) {
                toaster.error("Can't upload more than 3 files.");
                _.defer(function () {
                    $scope.$apply();
                });
                return false;
            }

            if (filesSelected.length > 0) {
                angular.forEach(filesSelected, function (value, key) {
                    var fileToLoad = value;
                    var fileReader = new FileReader();
                    fileReader.onload = function (fileLoadedEvent) {
                        var newdata = fileLoadedEvent.target.result;
                        imageExtn = fileToLoad.name.substr(fileToLoad.name.lastIndexOf('.') + 1);
                        if (imageExtn.toLowerCase() == "png" || imageExtn.toLowerCase() == "jpg" || imageExtn.toLowerCase() == "jpeg" || imageExtn.toLowerCase() == "pdf") {
                            var FileSize = fileToLoad.size / 1024 / 1024; // in MB
                            if (FileSize > 2) {
                                toaster.error("File size should not exceed 2 MB");
                                document.getElementById("imageNotes").value = "";
                                _.defer(function () {
                                    $scope.$apply();
                                });
                                return;
                            }

                            if ($scope.orderDet.orderNotes.urls !== undefined && $scope.orderDet.orderNotes.urls.filter(x => x.isDeleted !== true).length >= 3) {
                                toaster.error("Can't upload more than 3 files.");
                            } else {
                                var urls = {};
                                urls.imageName = fileToLoad.name;
                                // if file/image name is too long then display short name by appending .... at the end of name//
                                var fileNamewithExt = fileToLoad.name;
                                var lastIndex = fileNamewithExt.lastIndexOf('.');
                                var name = fileNamewithExt.substring(0, lastIndex);
                                urls.showUrl = name + '.' + imageExtn;//name.length < 18 ? fileToLoad.name : ((name.substring(0, 18)) + "..." + imageExtn);
                                //end
                                urls.imageData = newdata;
                                urls.imageExtn = imageExtn;
                                urls.url = fileLoadedEvent.target.result;
                                urls.enableDelete = true;
                                urls.isSaved = false;
                                urls.isDeleted = false;
                                urls.urlId = 0;

                                $scope.lensNoteUrls = $scope.ctrl.orderNotes.urls !== undefined ? $scope.ctrl.orderNotes.urls : [];
                                if (!$scope.orderDet.orderNotes && !$scope.orderDet.orderNotes.urls) {
                                    $scope.orderDet.orderNotes.urls = [];
                                }
                                $scope.lensNoteUrls.push(urls);
                                $scope.orderDet.orderNotes.urls.push(urls);
                                $scope.ctrl.orderNotes.urls = $scope.lensNoteUrls;
                            }
                        }
                        else {
                            toaster.error("Please select png, jpg, jpeg, pdf formats only.");
                        }
                        _.defer(function () {
                            $scope.$apply();
                        });
                    };
                    fileReader.readAsDataURL(fileToLoad);
                });
            }
        }

        $scope.ctrl.saveOrderNotes = function () {
            $scope.orderDet.orderNotes.urls = $scope.orderDet.orderNotes.urls.filter(x => x.isDeleted !== true);
            $scope.orderDet.orderNotes.urls.forEach(x => x.isSaved = true);
            $scope.orderDet.orderNotes.note = $scope.ctrl.orderNotes.notes;
        }

        $scope.ctrl.getVoOrderNotes = (orderId) => {
            orderDetailResource.getVoOrderNotes({ orderId: orderId }, function (data) {
                $scope.orderDet.orderNotes = data.orderNotes;
            });
        }

        $scope.ctrl.deleteImageUrl = function (index) {
            document.getElementById("imageNotes").value = "";
            $scope.orderDet.orderNotes.urls[index].isDeleted = true;
        }

        $scope.displayActiveUrls = function (item) {
            return item.isDeleted != true;
        };

        $scope.ctrl.cancelOrderNotes = () => {
            $scope.ctrl.orderNotes = {
                notes: $scope.orderDet.orderNotes.note
            };

            $scope.orderDet.orderNotes.urls = $scope.orderDet.orderNotes.urls.filter(x => x.isSaved !== false)
            $scope.orderDet.orderNotes.urls.forEach(x => x.isDeleted = false);
            document.getElementById("imageNotes").value = "";
        }

        $scope.ctrl.openFile = function (i) {
            if (i.enableDelete == false) {
                window.open(i.url);
            }
            else {
                if (i.imageExtn.toLowerCase() !== "pdf") {
                    var image = new Image();
                    image.src = i.url;
                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else {
                    var win = window.open();
                    win.document.write('<iframe src="' + i.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                }
            }
        }
    });

}(window.angular));